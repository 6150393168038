<script>
    import {createEventDispatcher} from "svelte";
    import FormInput from "@/components/FormInput.svelte";

    export let name;
    export let label;
    export let value;
    export let checked = false;
    export let disabled = false;
    export let required = false;

    const dispatch = createEventDispatcher();
</script>

<div>
    <FormInput {name}>
        <label>
            <input type="checkbox"
                   name={name}
                   value={value}
                   bind:checked={checked}
                   on:change
                   required={required ? "required" : ""}
                   disabled={disabled ? "disabled" : ""} />
            <span class="aid-checkbox__checkbox"></span>
            {#if label}
                <span>{label}</span>
            {:else}
                <slot />
            {/if}
        </label>
    </FormInput>
</div>


<style>
    @import '../common/colors.css';

    div {
        display: block;
        font-size: 16px;
    }

    /* Hide the checkbox, but preserve it's normal flow in the form */
    div input {
        position: absolute;
        appearance: none;
        opacity: 0;
    }

    div input:focus + .aid-checkbox__checkbox {
        border-color: var(--black);
    }

    div input:checked:focus + .aid-checkbox__checkbox {
        outline: 2px solid var(--pink);
    }

    div label {
        display: flex;
        flex-direction: row;
        align-items: center;

        cursor: pointer;
        user-select: none;

        /* Contain the checkbox */
        position: relative;
    }

    div .aid-checkbox__checkbox {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background: var(--white);
        border: 2px solid var(--fieldGray);
    }

    div input:checked + .aid-checkbox__checkbox {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='30'%3E%3Cpath d='M32.6 3.05a6.68 6.68 0 0 1-2.15 4.61l-.22.23-12.51 13.17a79.32 79.32 0 0 1-6.78 6.48q-2.8 2.28-4 2.28a6 6 0 0 1-3-1.14 5.73 5.73 0 0 1-2.32-2.36A18 18 0 0 1 .8 22a58 58 0 0 1-.4-7.1 5.43 5.43 0 0 1 1.74-3.86A5.3 5.3 0 0 1 6 9.3q2 0 2.38 3.7c0 .28 0 .49.06.62a18.29 18.29 0 0 0 .74 3.93c.29.74.67 1.11 1.16 1.11a2.6 2.6 0 0 0 1.13-.66 22.61 22.61 0 0 0 2-1.79L26.3 3.46A18.59 18.59 0 0 1 29.2 1a4.15 4.15 0 0 1 2.16-.82 1 1 0 0 1 1 .5 3.7 3.7 0 0 1 .29 1.7z' fill='%23FFFFFF'/%3E%3C/svg%3E");
        background-size: 70%;
        background-repeat: no-repeat;
        background-position: center;
        color: var(--white);
        border-color: var(--black);
        background-color: var(--black);
    }
</style>